<template>
  <div>
    <div class="subheader subheader-transparent gradient-background"></div>
    <div
      class="subheader subheader-transparent input-project-image"
      v-bind:class="subheaderClasses"
      id="kt_subheader"
      :style="{ background: headerImage }"
    >
      <div
        class="d-flex align-items-center justify-content-center flex-wrap flex-sm-nowrap"
        v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
      >
        <!--begin::User-->
        <div
          v-if="projectBrandObject[7].value == null && currentUser.system_register_type_id != 2"
          class="text-center mb-10"
        >
          <div class="symbol symbol-90 symbol-circle symbol-xl-150">
            <UserThumbnail
              :user="currentUser"
              size="150"
              shape="circle"
            ></UserThumbnail>
          </div>

          <h3 class="font-weight-bold my-2 text-white">
            <AnonymisedUsername :user="currentUser"></AnonymisedUsername>
          </h3>
          <div class="mb-2 text-white font-size-lg">
            <AnonymisedEmail :user="currentUser"></AnonymisedEmail>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";

export default {
  name: "KTSubheader",
  props: {
    subheaderClasses: String,
    widthFluid: String
  },
  components: {
    UserThumbnail
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "projectBrandObject"
    ]),
    currentUserPhoto() {
      return this.currentUser.signedAvatar;
    },
    headerImage: function() {
      let stockBanner = `/img/fallbacks/banner/_01.jpg`;
      let backgroundConfig = this.projectBrandObject[7];

      if (backgroundConfig.project_asset.length > 0) {
        return `url(${backgroundConfig.project_asset[0].signedUrl})`;
      } else {
        return `url(${stockBanner})`;
      }
    }
  }
};
</script>
<style scoped>
span.symbol-label.font-size-h5 {
  font-size: 70px !important;
}
.text-center.mb-10 {
  z-index: 1;
}
.gradient-background {
  position: absolute;
  width: 100%;
  z-index: 0;
}
</style>

<template>
  <ul id="topbar-menu" class="menu-nav">
    <!-- Begin::Home -->
    <router-link
      tag="a"
      :to="{
        name: 'view_activities'
      }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> {{ $t("DASHBOARD.HOME.TITLE") }} </span>
        </a>
      </li>
    </router-link>
    <!-- End::Home -->

    <!-- Begin::My page -->
    <router-link
      v-if="userId"
      :to="`/community/${userId}`"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> {{ $t("DASHBOARD.MY_PAGE.TITLE") }} </span>
        </a>
      </li>
    </router-link>
    <!-- End::My page -->

    <!-- Begin::Forum Page-->
    <router-link
      v-if="projectSupportsForums && currentUser.relation_to_project != 'observer'"
      :to="{ name: 'view_forum' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> {{ $t("DASHBOARD.FORUM.TITLE") }} </span>
        </a>
      </li>
    </router-link>
    <!-- End::Forum Page-->

    <!-- Begin::Other Pages -->
    <template v-if="pageList.length > 3">
      <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/page') }"
      >
        <a href="#" class="menu-link menu-toggle">
          <span class="menu-text"> Other Pages </span>
        </a>
        <div class="menu-submenu menu-submenu-classic">
          <ul class="menu-subnav">
            <template v-for="(item, key) in pageList">
              <router-link
                :to="pageLink(item)"
                v-bind:key="key"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text"> {{ item.title }} </span>
                  </a>
                </li>
              </router-link>
            </template>
          </ul>
        </div>
      </li>
    </template>
    <template v-else>
      <template v-for="(item, key) in pageList">
        <router-link
          :to="pageLink(item)"
          v-bind:key="key"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <span class="menu-text">
                {{ item.title }}
              </span>
            </a>
          </li>
        </router-link>
      </template>
    </template>
    <!-- End::Other Pages -->
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PROJECT_INFO } from "@/core/services/store/project/project.module";
import { UPDATE_CLIENT_INFO } from "@/core/services/store/core/client.module";
import { POPULATE_PROJECT_WIDGET_LIST } from "@/core/services/store/project/project_widgets.module";
import { POPULATE_PAGE_LIST } from "@/core/services/store/page/page.module";

export default {
  name: "KTMenu",
  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 4
      }
    };
  },
  mounted() {
    this.preloadClient();
    this.preloadProject();
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    preloadClient() {
      this.$store
        .dispatch(UPDATE_CLIENT_INFO, this.clientInfo.id)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.$router.push({ name: "404" });
        });
    },
    preloadProject() {
      let payload = {
        clientIdentifier: this.clientInfo.id,
        projectIdentifier: this.projectInfo.id,
        params: {
          domain: this.projectIdentifier,
          ProjectIntroduction: "with",
          paginate: false
        }
      };
      this.$store
        .dispatch(UPDATE_PROJECT_INFO, payload)
        .then(() => {
          this.preloadProjectWidgets();
          this.preloadProjecPages();
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.$router.push({ name: "404" });
        });
    },
    preloadProjectWidgets() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          projectWidgetConfigs: "with",
          projectAssets: "with",
          appendSignedUrls: 1,
          position: "ASC",
          activities: "with"
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_WIDGET_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.$router.push({ name: "404" });
        });
    },
    preloadProjecPages() {
      let payload = {
        projectId: this.projectInfo.id
      };
      this.$store
        .dispatch(POPULATE_PAGE_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.$router.push({ name: "404" });
        });
    },

    pageLink(page) {
      return "/page/" + page.id;
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "projectWidgets",
      "projectInfo",
      "pageList",
      "clientInfo",
      "projectInfo"
    ]),
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    },
    projectIdentifier: function() {
      let subDomain = window.location.host.split(".")[0];

      if (window.location.host.includes(process.env.VUE_APP_API_ROOT)) {
        return subDomain;
      } else {
        return subDomain.split("-")[0];
      }
    },
    clientIdentifier: function() {
      let subDomain = window.location.host.split(".")[0];
      return subDomain.split("-")[1];
    },
    computedWidgets: function() {
      let widgetScope = this.$route.meta.widgets;
      let widgetsArray = [];
      const self = this;

      if (widgetScope == "global") {
        widgetsArray = this.projectWidgets.filter(function(widget) {
          return widget.activities.length == 0;
        });
      } else if (widgetScope == "activity_limited") {
        widgetsArray = this.projectWidgets.filter(function(widget) {
          return (
            widget.activities.filter(function(activity) {
              return activity.id == self.$route.params.id;
            }).length > 0 || widget.system_widget_types_id == 7
          );
        });
      } else if (widgetScope == "cm_only") {
        widgetsArray = this.projectWidgets.filter(function(widget) {
          return widget.system_widget_types_id == 7;
        });
      } else {
        widgetsArray = [];
      }

      return widgetsArray;
    },
    userId: function() {
      return this.currentUser.projectProfile
        ? this.currentUser.projectProfile.id
        : null;
    },
    projectSupportsForums: function() {
      return this.projectInfo.enableForums;
    }
  }
};
</script>
<template>
  <!-- begin:: Footer -->
  <div class="footer py-4 d-flex flex-lg-column bg-white" id="kt_footer">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div
        id="together_authentication_footer_brand"
        class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2 font-size-sm"
      >
        Powered by Toluna Start
      </div>
      <div
        id="together_authentication_footer_links"
        class="d-flex order-1 order-sm-2 my-2"
      >

        <component
          v-if="termsType != null"
          :is="termsType.component"
          v-bind="termsType.data"
          class="mx-2"
          :title="$t('NAVIGATION.FOOTER.CUSTOM_T&C')"  
        >
          {{ $t('NAVIGATION.FOOTER.T&C') }}
        </component>

        <component
          v-if="privacyType != null"
          :is="privacyType.component"
          v-bind="privacyType.data"
          class="mx-2"
          :title="$t('NAVIGATION.FOOTER.PRIVACY')"  
        >
          {{ $t('NAVIGATION.FOOTER.PRIVACY') }}
        </component>

        <a href="javascript:void(0)" class="text-dark-75 text-hover-primary mx-2">{{ version }}</a>
      </div>
    </div>
    <!--end::Container-->

  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

import FooterContentModal from "@/view/components/modals/FooterContentModal";

export default {
  name: "KTFooter",

  components: {
    FooterContentModal
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "layoutConfig",
      "version",
      "currentUser",
      "projectBrandObject"
    ]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return true;
    },

    profile: function() {
      let projectId = this.projectInfo.id;

      let relevantProfile = this.currentUser.project_profiles.filter(function(profile) {
        return profile.project_id == projectId;
      });

      return (relevantProfile.length == 1) ? relevantProfile[0] : null;
    },

    /**
     * Toluna Sample and non participants see toluna terms
     * My own sample sees the custom terms
     * 
     * @return Object
     */
    termsType: function() {
      if (this.profile == null || this.profile.system_project_user_sample_id == 2) { //Toluna Sample
        return {
          component: "a",
          data: {
            href: "https://www.toluna.com/terms",
            target: "blank",
            class: "text-dark-75 text-hover-primary ml-4"
          }
        };
      } else if (this.profile.system_project_user_sample_id == 1 && this.projectBrandObject[12].value != null) {
        let config = this.projectBrandObject[12];
        if (config.value == 1) {
          return {
            component: "a",
            data: {
              href: config.decodedOptions.link,
              target: "blank",
              class: "text-dark-75 text-hover-primary ml-4"
            }
          }
        } else if (config.value == 2) {
          return {
            component: "FooterContentModal",
            data: {
              id: "termsModal",
              content: config.decodedOptions.message
            }
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    /**
     * Toluna Sample and non participants see toluna privacy link
     * My own sample sees the custom privacy link
     * 
     * @return Object
     */
    privacyType: function() {
      if (this.profile == null || this.profile.system_project_user_sample_id == 2) { //Toluna Sample
        return {
          component: "a",
          data: {
            href: "https://www.toluna.com/privacy",
            target: "blank",
            class: "text-dark-75 text-hover-primary ml-4"
          }
        };
      } else if (this.profile.system_project_user_sample_id == 1 && this.projectBrandObject[13].value != null) {
        let config = this.projectBrandObject[13];
        if (config.value == 1) {
          return {
            component: "a",
            data: {
              href: config.decodedOptions.link,
              target: "blank",
              class: "text-dark-75 text-hover-primary ml-4"
            }
          }
        } else if (config.value == 2) {
          return {
            component: "FooterContentModal",
            data: {
              id: "privacyModal",
              content: config.decodedOptions.message
            }
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
  }
};
</script>
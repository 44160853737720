<template>
  <div class="topbar-item">
    <div
      id="kt_quick_user_toggle"
      v-b-tooltip.hover.bottom
      :title="$t('TOOLBAR.USER.TOOLTIP')"
      no-caret
      class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
    >
      <span
        class="text-primary opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        {{ $t("TOOLBAR.USER.HI") }}
      </span>
      <span
        class="text-primary opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4"
      >
        <AnonymisedUsername
          :user="currentUser"
        >
        </AnonymisedUsername>
      </span>
      <UserThumbnail :user="currentUser" size="35"></UserThumbnail>
    </div>
    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          {{ $t("TOOLBAR.USER.TITLE") }}
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <UserThumbnail :user="currentUser" size="90"></UserThumbnail>
            <i class="symbol-badge bg-success"></i>
          </div>
          <div
            class="d-flex flex-column"
          >
            <a
              v-if="currentUser.system_register_type_id != 2"
              href="javascript:void(0)"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              <AnonymisedUsername :user="currentUser"></AnonymisedUsername>
            </a>
            <div
              v-if="currentUser.system_register_type_id != 2"
              class="text-muted mt-1"
            >
              <AnonymisedEmail :user="currentUser"></AnonymisedEmail>
            </div>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <router-link
            v-if="currentUser.system_register_type_id != 2"
            @click.native="closeOffcanvas"
            :to="{ name: 'profile' }"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/General/Notification2.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">
                  {{ $t("TOOLBAR.USER.PROFILE_DETAIL") }}
                </div>
                <div class="text-muted">
                  {{ $t("TOOLBAR.USER.PROFILE_DETAIL_DESC") }}
                </div>
              </div>
            </div>
          </router-link>

          <a
            href="javascript:void(0)"
            @click="onLogout"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/General/Lock.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">
                  {{ $t("TOOLBAR.USER.SIGN_OUT") }}
                </div>
              </div>
            </div>
          </a>
          <!--end:Item-->
        </div>
        <!--end::Nav-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";
import { LOGOUT } from "@/core/services/store/system/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",
  data() {
    return {
      list: []
    };
  },
  components: {
    UserThumbnail
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),

    firstInitial: function() {
      return this.currentUser.display_name[0].toUpperCase();
    },

    picture() {
      return this.currentUser.signedAvatar;
    }
  }
};
</script>
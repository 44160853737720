<template>
  <div>
    <a
      href="javascript:void(0)"
      v-b-modal="`modal-${id}`"
    >
      {{ title }}
    </a>

    <b-modal
      :id="`modal-${id}`"
      :title="title"
      size="xl"
    >
      <span v-html="content"></span>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "FooterContentModal",

  props: {
    id: { type: String, required: true },
    title: { type: String, required: true },
    content: { type: String, required: true }
  }
}
</script>
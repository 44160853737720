<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile
      v-if="pageLoader.componentsRequired <= pageLoader.componentsCompleted"
    ></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div
      v-if="pageLoader.componentsRequired <= pageLoader.componentsCompleted"
      class="d-flex flex-row flex-column-fluid page"
    >
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader v-if="subheaderDisplay" />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid page-bg-color">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
              class="mx-0 px-0"
              style="max-width:100%!important"
            >
              <div class="d-lg-flex flex-row-fluid">
                <!-- begin:: Aside Left -->
                <KTAside v-if="asideEnabled"></KTAside>
                <!-- end:: Aside Left -->

                <div class="content-wrapper flex-row-fluid shrink-top">
                  <transition name="fade-in-up">
                    <router-view />
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>

        <KTFooter></KTFooter>
      </div>
    </div>
    <!-- <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar> -->
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations
} from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
// import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/modules/together-helpers/components/application-components/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/system/htmlclass.module.js";
import { POPULATE_DEFAULT_LANGUAGE } from "@/modules/together-helpers/components/generic-features/languages/vuex/i18n.module";
import { POPULATE_LANGUAGES } from "@/core/services/store/system/system.module";
import { UPDATE_PROJECT_INFO } from "@/core/services/store/project/project.module";
import { UPDATE_CLIENT_INFO } from "@/core/services/store/core/client.module";
import { POPULATE_PROJECT_WIDGET_LIST } from "@/core/services/store/project/project_widgets.module";
import { POPULATE_PAGE_LIST } from "@/core/services/store/page/page.module";
import { POPULATE_VERSION } from "@/modules/together-helpers/vuex/system/version.module";

export default {
  name: "Layout",
  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 8
      }
    };
  },
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    // KTStickyToolbar,
    KTScrollTop,
    Loader
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // get version
    this.$store.dispatch(POPULATE_VERSION)
      .then(() => {
        this.pageLoader.componentsCompleted++;
      })

    // fetch defaults
    this.fetchSupportedLanguages();
    this.fetchProjectBrand();

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }
  
    this.preloadClient();
    this.preloadProject();

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  methods: {
    preloadClient(clientId = null) {
      
      this.$store
        .dispatch(UPDATE_CLIENT_INFO, (clientId == null) ? this.clientIdentifier : this.clientId)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.$router.push({ name: "404" });
        });
    },
    preloadProject() {
      let payload = {
        clientIdentifier: this.clientIdentifier,
        projectIdentifier: this.projectIdentifier,
        params: {
          domain: this.projectIdentifier,
          ProjectIntroduction: "with",
          paginate: false
        }
      };
      this.$store
        .dispatch(UPDATE_PROJECT_INFO, payload)
        .then(() => {
          this.preloadProjectWidgets();
          this.preloadProjecPages();
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.$router.push({ name: "404" });
        });
    },
    preloadProjectWidgets() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          projectWidgetConfigs: "with",
          projectAssets: "with",
          appendSignedUrls: 1,
          position: "ASC",
          activities: "with"
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_WIDGET_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.$router.push({ name: "404" });
        });
    },
    preloadProjecPages() {
      let payload = {
        projectId: this.projectInfo.id
      };
      this.$store
        .dispatch(POPULATE_PAGE_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.$router.push({ name: "404" });
        });
    },
    fetchSupportedLanguages: function() {
      this.$store.dispatch(POPULATE_LANGUAGES, false).then(() => {
        this.pageLoader.componentsCompleted++;
        this.$store
          .dispatch(
            POPULATE_DEFAULT_LANGUAGE,
            this.currentUser.system_language_code
          )
          .then(() => {
            this.pageLoader.componentsCompleted++;
          });
      });
    },
    fetchProjectBrand: function() {
      let payload = {
        route: `api/v1/projects/${window.location.host.split(".")[0]}/project_brands`,
        params: {
          domain: this.projectIdentifier,
          projectAsset: "with",
          appendSignedUrls: 1
        }
      };
      this.$store.dispatch(GENERIC_RESOURCE, payload)
        .then((res) => {
          this.setProjectBrandList(res._embedded.project_brands);
          this.setProjectBrandObject(res._embedded.project_brands);
          this.pageLoader.componentsCompleted++;
        });
    },
    ...mapMutations({
      setProjectBrandList: "setProjectBrandList",
      setProjectBrandObject: "setProjectBrandObject"
    })
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "isAuthenticated",
      "projectInfo",
      "projectBrandList",
      "projectBrandObject",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    // toolbarDisplay() {
    //   // return !!this.layoutConfig("toolbar.display");
    //   return true;
    // },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },

    customDomain: function() {
      let subDomain = window.location.host.split('.')[0];
      return (subDomain.includes("-") ? false : true);
    },

    projectIdentifier: function() {
      let subDomain = window.location.host.split('.')[0];

      if (window.location.host.includes(process.env.VUE_APP_API_ROOT)) {
        return subDomain;
      } else {
        return subDomain.split('-')[0];        
      }
    },

    clientIdentifier: function() {
      if (this.customDomain) {
        return document.domain.split('.').reverse().splice(0,2).reverse().join('.');
      } else {
        let subDomain = window.location.host.split(".")[0];
        return subDomain.split("-")[1];        
      }
    },
  }
};
</script>

<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar topbar d-flex align-items-center">

    <ProjectSelect />
    <Notifications />
    <Helpdesk />

    <!--begin: Admin Toggle -->
    <div>
      <b-button
        v-if="isAdminUser"
        class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg"
        v-b-tooltip.hover
        :title="$t('NAVIGATION.TOOLTIPS.ADMIN')"
      >
        <a :href="projectInfo.adminLink + '/#/project/view'">
          <span
            class="svg-icon svg-icon-xl svg-icon-primary"
          >
            <inline-svg src="media/svg/icons/General/Settings-2.svg" />
          </span>
        </a>
      </b-button>      
    </div>
    <!--end: Admin Toggle -->

    <!--begin: Dropdown Language bar -->
    <DropdownLanguage></DropdownLanguage>
    <!--end: Dropdown Language bar -->

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import ProjectSelect from "@/modules/together-helpers/components/generic-features/ProjectSelection.vue";
import Notifications from "@/modules/together-helpers/components/generic-features/Notifications.vue";
import DropdownLanguage from "@/modules/together-helpers/components/generic-features/LanguageSelection.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import Helpdesk from "@/modules/together-helpers/help/Helpdesk.vue";

export default {
  name: "KTTopbar",

  components: {
    ProjectSelect,
    Notifications,
    DropdownLanguage,
    KTQuickUser,
    Helpdesk
  },

  computed: {
    ...mapGetters(["userLanguage", "currentUser", "projectInfo"]),

    getLanguageFlag() {
      return "media/svg/flags/" + this.userLanguage.flag_icon;
    },

    isAdminUser: function() {
      if (
        this.currentUser.system_admin_role_id == 1 ||
        this.currentUser.system_admin_role_id == 2
      ) {
        return true;
      } else if (this.currentUser.clients.length > 0) {
        const self = this;
        let eligibleClientRoles = this.currentUser.clients.filter(function(
          client
        ) {
          return client.id == self.projectInfo.client_id;
        });
        if (eligibleClientRoles.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        const self = this;
        let eligibleProjectRoles = this.currentUser.project_profiles.filter(
          function(profile) {
            return (profile.project_id ==
              self.projectInfo.id && profile.system_project_role_id > 1);
          }
        );
        if (eligibleProjectRoles.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
};
</script>
